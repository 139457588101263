import React from 'react';
import { ButtonCont } from '../../styles/components/faqs.styled';


const button = ({text, onClick, state = false}) => {
  return(
    <ButtonCont onClick={onClick} state={state} >
      {text}
    </ButtonCont>
  )
};

export default button;