import React from 'react';
import { TitleCont } from '../../styles/components/faqs.styled';


const Title = ({title, subTitle}) => {
  return(
    <TitleCont>
      <h1> {title} </h1>
      <p> {subTitle} </p>
    </TitleCont>
  )
};

export default Title;