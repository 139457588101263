import React from "react";
import { QuestionCont } from "../../styles/components/faqs.styled";


const Question = ({ title, text }) => {
  return (
    <QuestionCont>
      <h3> {title} </h3>
      <p className="text">{text}</p>
      {/* <button className="link">Ver más</button> */}
    </QuestionCont>
  );
};

export default Question;
