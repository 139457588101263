import React, { useState } from "react";
import { FaqsCont } from "../styles/components/faqs.styled";

import Seo from "../components/seo";
import Layout from "../components/layout";
import Title from "../components/faqs/Title";
import Button from "../components/faqs/button";
import Question from "../components/faqs/Question";
import ButtonAccordion from "../components/faqs/ButtonAccordion"

export const faqsList = {
  plataforma: [
    {
      title: "¿Qué hacemos?",
      text:
        "MyOblek simplifica, automatiza y optimiza la forma en la que realizas tu monitoreo de medios de comunicación.",
    },
    {
      title: "¿De donde traemos la información?",
      text:
        "Monitoreamos 1,750 medios de comunicación las 24 horas x 7 días a la semana los 365 días del año. Ingresamos a nuestra base de datos propia el contenido digitalizado de medios tradicionales (radio, televisión, revistas y periódicos) y de medios digitales (portales web, redes sociales). ",
    },
    {
      title: "¿Cómo lo hacemos?",
      text:
        "Una vez suscrito, dentro de la plataforma MyOblek, conforme a tu búsqueda, podrás crear tableros donde de manera muy fácil e intuitiva podrás seleccionar gráficos y distintas formas para mostrar los analíticos de las notas que trajo tu búsqueda.",
    },
    {
      title: "¿Por qué MyOblek?",
      text:
        "Por que la base de datos a la que estamos conectados es propia, lo que nos ha permitido perfeccionarla, robustecerla, crecerla y optimizarla siguiendo las mejores prácticas y conforme a las nuevas tecnologías.",
    },
  ],
  planes: [
    {
      title: "¿Qué planes tienen?",
      text: (
        <>
          Individual desde $3,900.00 MXN al mes, <br />
          Equipo desde $8,900.00 MXN al mes, <br />
          Corporativo desde $14,240.00 MXN. Para revisar el detalle de cada
          plan has clic <a href="https://www.myoblek.com/#precios">aquí</a>
        </>
      ),
    },
    {
      title: "¿Tienen descuentos o promociones?",
      text: (
        <>
          ¡Sí! Recibe hasta 50% de descuento* al contratar la anualidad.
          *Revisa términos y condiciones <a href="https://www.myoblek.com/#precios">aquí</a>
        </>
      ),
    },
    {
      title: "¿Pueden configurar un plan para mi?",
      text: (
        <>
          ¡Claro! Por favor envíanos un correo electrónico describiéndonos tu
          necesidad y nos pondremos en contacto contigo{" "}
          <b>hola@myoblek.com</b>
        </>
      ),
    },
  ],
  pagos: [
    {
      title: "¿Mi información está protegida contra terceros?",
      text: (
        <>
          Haz clic aquí para conocer nuestro{" "}
          <a href="https://www.myoblek.com/aviso">aviso de privacidad.</a>
        </>
      ),
    },
    {
      title: "¿Qué métodos de pago aceptan?",
      text: "Por el momento sólo aceptamos métodos de pago electrónicos.",
    },
    {
      title: "¿Cómo solicito una factura?",
      text:
        "Ajustes/ pagos/ selecciona “Necesito factura” y se habilitarán los campos para que se llene ese apartado.",
    },
    {
      title: "No recibí mi factura",
      text: (
        <>
          Por favor ponte en contacto con nosotros. Envíanos un correo{" "}
          <b>hola@myoblek.com</b>.
        </>
      ),
    },
    {
      title: "No recibí la confirmación de mi compra",
      text: (
        <>
          <ul>
            <li>
              En tu bandeja de correos e- revisa las carpetas ‘No deseado’ o
              ‘Spam’. Si está aquí, te recomendamos que nos agregues a tu
              lista de contactos para que puedas recibir nuestras
              notificaciones de forma regular.
            </li>
            <li>
              Intenta iniciar sesión, si pudiste ¡No hay de qué preocuparse!
              tu suscripción está activa y lista para usarse.
            </li>
          </ul>
          <br />
          Si no resolvimos tu problema, por favor ponte en contacto con
          nosotros. Envíanos un correo
          <b>hola@myoblek.com</b>
        </>
      ),
    },
  ],
  usuarios: [
    {
      title: "¿Cómo restablezco mi contraseña?",
      text: (
        <>
          Al momento de iniciar sesión, has clic en ‘olvidé mi contraseña’ y
          sigue las instrucciones para poder restablecerla. También puedes
          acceder desde <a href="https://app.myoblek.com/recover"> aquí</a>
        </>
      ),
    },
    {
      title: "¿Cómo cancelo mi cuenta?",
      text:
        "No nos gustaría verte partir, pero si así lo decides - Inicia sesión y en Ajustes’ seleccione ‘Eliminar cuenta’.",
    },
  ],
};

const Faqs = () => {
  const [section, setSection] = useState(1);
  const [sectionMobile, setSectionMobile] = useState(null);
  return (
    <Layout>
      <Seo title="FAQ's" />
      <FaqsCont>
        <div className="faqsCont">
          <div className="faqsCont-title">
            <Title
              title="¿Tienes alguna duda?"
              subTitle="Déjanos ayudarte a encontrar una respuesta"
            />
          </div>
          <div className="faqsCont-desktop">
            <div className="faqsCont-desktop-buttons">
              <Button
                text="Nuestra plataforma"
                state={section === 1}
                onClick={() => setSection(1)}
              />
              <Button
                text="Nuestros planes"
                state={section === 2}
                onClick={() => setSection(2)}
              />
              <Button
                text="Pagos y Facturación"
                state={section === 3}
                onClick={() => setSection(3)}
              />
              <Button
                text="Usuarios MyOblek"
                state={section === 4}
                onClick={() => setSection(4)}
              />
            </div>
            <div className="faqsCont-desktop-section">
              {section === 1 && (
                faqsList.plataforma.map((e) => (
                  <Question
                    title={e.title}
                    text={e.text}
                  />
                ))
              )}
              {section === 2 && (
                faqsList.planes.map((e) => (
                  <Question
                    title={e.title}
                    text={e.text}
                  />
                ))
              )}
              {section === 3 && (
                faqsList.pagos.map((e) => (
                  <Question
                    title={e.title}
                    text={e.text}
                  />
                ))
              )}
              {section === 4 && (
                faqsList.usuarios.map((e) => (
                  <Question
                    title={e.title}
                    text={e.text}
                  />
                ))
              )}
            </div>
          </div>
          <div className="faqsCont-mobile">
            <div className="faqsCont-mobile-section">
              <ButtonAccordion 
                state={sectionMobile === 1}
                onClick={() => sectionMobile === 1 ? setSectionMobile(null) : setSectionMobile(1) }
                title='Nuestra plataforma' 
                content={
                  faqsList.plataforma.map((e) => (
                    <Question
                      title={e.title}
                      text={e.text}
                    />
                  ))
                }
              />
              <ButtonAccordion 
                state={sectionMobile === 2}
                onClick={() => sectionMobile === 2 ? setSectionMobile(null) : setSectionMobile(2) }
                title='Nuestros planes' 
                content={
                  faqsList.planes.map((e) => (
                    <Question
                      title={e.title}
                      text={e.text}
                    />
                  ))
                }
              />
              <ButtonAccordion 
                state={sectionMobile === 3}
                onClick={() => sectionMobile === 3 ? setSectionMobile(null) : setSectionMobile(3) }
                title='Pagos y Facturación' 
                content={
                  faqsList.pagos.map((e) => (
                    <Question
                      title={e.title}
                      text={e.text}
                    />
                  ))
                }
              />
              <ButtonAccordion 
                state={sectionMobile === 4}
                onClick={() => sectionMobile === 4 ? setSectionMobile(null) : setSectionMobile(4) }
                title='Usuarios MyOblek' 
                content={
                  faqsList.usuarios.map((e) => (
                    <Question
                      title={e.title}
                      text={e.text}
                    />
                  ))
                }
              />
            </div>
          </div>
        </div>
      </FaqsCont>
    </Layout>
  );
};

export default Faqs;
