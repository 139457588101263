import styled from "styled-components";
import { colors } from "../variables";

export const FaqsCont = styled.div`
  .faqsCont {
    &-title {
    }
    &-desktop {
      display: flex;
      flex-direction: column;
      &-buttons {
        padding: 0 10%;
        display: flex;
        justify-content: space-between;
      }
      &-section {
        background: ${colors.lightBlueBg};
        padding: 60px 10%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        min-height: 500px;
      }
    }
    &-mobile {
      display: none;
    }
  }
  @media (max-width: 1215px) {
    .faqsCont {
      &-desktop {
        &-buttons {
          padding: 0%;
        }
        &-section {
          padding: 60px 5%;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .faqsCont {
      &-desktop {
        display: none;
      }
      &-mobile {
        display: flex;
        flex-direction: column;
      }
    }
  }
`;

export const TitleCont = styled.div`
  margin: 80px 0;
  h1 {
    font-family: Montserrat Semi Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: ${colors.richBlack};
  }
  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: ${colors.richBlack};
  }
  @media (max-width: 580px) {
    h1 {
      font-size: 24px;
      line-height: 29px;
    }
    p {
      font-size: 24px;
      line-height: 29px;
    }
  }
`;

export const ButtonCont = styled.button`
  color: ${(e) => (e.state ? colors.purple : colors.grey)};
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 190.9%;
  background: ${(e) => (e.state ? colors.lightBlueBg : colors.white)};
  border: none;
  padding: 3px 16px;
  position: relative;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  transition: 0.4s;
  &:after {
    content: "";
    width: ${(e) => (e.state ? "90%" : "0%")};
    height: 2px;
    background: ${colors.purple};
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    margin: auto;
    transition: 0.2s;
  }
`;

export const QuestionCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin-bottom: 40px;
  margin-left: 20px;
  h3 {
    font-family: Montserrat Semi Bold;
    font-size: 18px;
    line-height: 34px;
    color: #00aaab;
  }
  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.richBlack};
    margin-top: 10px;
    a {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: ${colors.purple};
    }
    b {
      font-family: Montserrat Bold;
      font-size: 16px;
      line-height: 24px;
      color: ${colors.richBlack};
    }
    ul {
      list-style: decimal;
      margin-left: 40px;
      li {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  @media (max-width: 1280px) {
    max-width: 45%;
  }
  @media (max-width: 768px) {
    margin: auto;
    max-width: 580px;
    h3 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 24px;
        a {
          font-size: 15px;
          line-height: 24px;
        }
        b {
          font-size: 15px;
          line-height: 24px;
        }
        ul {
          li {
            font-size: 15px;
            line-height: 24px;
          }
        }
      }
    }
  }
`;

export const ButtonAccordionCont = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 57px;
  position: relative;
  margin-top: 20px;
  background: ${(e) => (e.state ? colors.lightBlueBg : "")};
  .buttonAtitle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 14px 20px;
    width: 100%;
    height: 57px;
    border-radius: 2px;
    position: relative;
    cursor: pointer;
    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 20px;
      color: ${colors.purple};
    }
    svg {
      -webkit-transition: ease-in-out 0.3s;
      -o-transition: ease-in-out 0.3s;
      transition: ease-in-out 0.3s;
      -webkit-transform: ${(props) =>
        props.state ? "rotate(0deg);" : "rotate(180deg);"};
      -ms-transform: ${(props) =>
        props.state ? "rotate(0deg);" : "rotate(180deg);"};
      transform: ${(props) =>
        props.state ? "rotate(0deg);" : "rotate(180deg);"};
    }
  }
  .textAccordion {
    overflow: hidden;
    -webkit-transition: ease-in-out 0.4s;
    -o-transition: ease-in-out 0.4s;
    transition: ease-in-out 0.4s;
    height: ${(props) => (props.state ? props.hy + "px" : "0px")};
    div {
      padding: 14px;
      -webkit-transition: ease-in-out 0.4s;
      -o-transition: ease-in-out 0.4s;
      transition: ease-in-out 0.4s;
    }
  }
  @media (max-width: 480px) {
    .buttonAtitle {
      p {
        font-size: 20px;
      }
    }
  }
`;
