import React, { useRef } from "react";
import { ButtonAccordionCont } from "../../styles/components/faqs.styled";
import { ReactComponent as DropSvg } from "../../assets/icons/arrowDropDown.svg";



const ButtonAccordion = ({ title, content, state, onClick }) => {
  const refElement = useRef(null);
  const heigth = refElement.current?.offsetHeight;
  return (
    <ButtonAccordionCont state={state} hy={heigth}>
      <div className="buttonAtitle" onClick={onClick}>
        <p>{title}</p>
        <DropSvg />
      </div>
      <div className="textAccordion">
        <div ref={refElement}>{content}</div>
      </div>
    </ButtonAccordionCont>
  );
};

export default ButtonAccordion;
